.welcomeContainer {
  width: 70rem;
  display: flex;
  margin-top: 1rem;
}

.welcome {
  width: 59rem;
  margin-left: 1rem;
}

.title {
  font-size: 1.4rem;
  color: gray;
}

.content {
  margin-top: 1rem;
  color: #a6a6a6;
  font-size: 0.8rem;
}

////////////  Fade animation  ////////////
// Title
.fade__title-appear {
  opacity: 0;
}

.fade__title-appear.fade__title-appear-active {
  opacity: 1;
  transition: opacity 200ms linear 200ms;
}

// Message
.fade__message-appear {
  opacity: 0;
}

.fade__message-appear.fade__message-appear-active {
  opacity: 1;
  transition: opacity 500ms linear 800ms;
}
