.container {
  width: 70rem;
  height: 55rem;
  margin-top: 1rem;
}

.setTitle {
    font-weight: bold;
}

.titleInput {
  width: 100%;
  font-size: 2rem;
  border-style: none;
  outline: none;
  font-weight: 600;
  color: #5b5b5b;
}

.titleInput:focus,
.titleInput.focus {
  width: 100%;
  font-size: 2rem;
  border-style: none;
  font-weight: 600;
  color: #5b5b5b;
}

.setCategoryContainer {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.setCategoryTitle {
    font-weight: bold;
    padding-bottom: 0.5rem;
}

.setCategory {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
}

.editorPreviewContainer {
  margin-top: 1rem;
  width: 100%;
  height: 20rem;
}

.preview {
  margin-top: 1rem;
  height: 111.5%;
}

.previewTitle {
  width: 100%;
  height: 2.5rem;
  font-size: 2.5rem;
  text-align: center;
  font-weight: 600;
  color: #5b5b5b;
}

.previewContent {
  overflow-y: auto;
  height: 550px;
  scrollbar-width: thin;
  scrollbar-color: #5b5c74 #d7c2c9;
  padding-right: 50px;
}

.error {
  font-size: small;
  font-weight: 650;
  text-align: center;
  color: #c8241b;
  padding-left: 5%;
}

.buttons {
  padding-top: 1rem;
  width: 100%;
}

.bottomRightButtons {
  float: right;
}

.discardButton {
  width: 7rem;
  height: 2.3rem;

  border-radius: 3px;
  border-style: none;

  background-color: #e6e6e6;
  color: black;

  font-weight: 600;
  cursor: pointer;
}

.discardButton:hover,
.discardButton.hover {
  width: 7rem;
  height: 2.3rem;

  border-radius: 3px;
  border-style: none;

  background-color: #666666;
  color: white;

  font-weight: 600;
  cursor: pointer;

  transition: 0.4s;
}

.previewButton {
  margin-right: 1rem;
  width: 10rem;
  height: 2.3rem;

  border-radius: 3px;
  border-style: none;

  background-color: #c9c9de;
  color: #4c4c93;

  font-weight: 600;
  cursor: pointer;
}

.previewButton:hover,
.previewButton.hover {
  margin-right: 1rem;
  width: 10rem;
  height: 2.3rem;

  border-radius: 3px;
  border-style: none;

  background-color: #4c4c93;
  color: white;

  font-weight: 600;
  cursor: pointer;

  transition: 0.4s;
}

.postButton {
  width: 8rem;
  height: 2.3rem;

  border-radius: 3px;
  border-style: none;

  background-color: #b2d8b2;
  color: #008000;

  font-weight: 600;
  cursor: pointer;
}

.postButton:hover,
.postButton.hover {
  width: 8rem;
  height: 2.3rem;

  border-radius: 3px;
  border-style: none;

  background-color: #008000;
  color: white;

  font-weight: 600;
  cursor: pointer;

  transition: 0.4s;
}

////////////  Fade animation  ////////////
// Appear
.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 200ms linear;
}
