.blogBody {
  width: 70rem;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.tableContainer {
  width: 59rem;
  margin-left: 1rem;
}

.headerTitle {
  width: 40rem;
  color: #777777;
  background-color: #f6f6f6;
  font-size: 0.9rem;
  border-radius: 5px 0 0 5px;
  border: solid 5px transparent;
  margin-left: 1rem;
}

.headerCreatedDate {
  width: 8rem;
  color: #777777;
  background-color: #f6f6f6;
  font-size: 0.9rem;
  border-radius: 0 5px 5px 0;
  border: solid 5px transparent;
  margin-left: 3rem;
}

.blogContainer {
  width: 59rem;
  margin-right: 1rem;
}

.tableEntry {
  text-decoration: none;
  color: black;
  margin-left: 0.65rem;
}
