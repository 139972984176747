.menuItem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.1rem;

  padding-left: 0.7rem;
  padding-right: 0.7rem;

  z-index: 1;
  transition: 0.4s ease-out;
}

.menuItem.hover,
.menuItem:hover {
  transition: 0.4s ease-out;
}
