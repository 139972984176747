.title {
  font-family: "Roboto", sans-serif;
  font-size: xx-large;
}

.date {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: small;
  opacity: 0.5;
}

.content {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
