.toolsContainer {
  width: 7rem;
  margin-left: 3rem;
}

.toolsTitle {
  font-weight: bold;
  margin-bottom: 0.4rem;
  margin-left: 1rem;
}

.toolsItemContainer {
  margin-bottom: 0.3rem;
  padding-right: 1.1rem;
  padding-left: 1rem;
  padding-bottom: 0.1rem;
  border-radius: 4px;
  transition: 0.4s ease;
  cursor: pointer;

  &:hover {
    background-color: #faeef3;
    box-shadow: 2px 1px 5px 1px rgba(0, 0, 0, 0.1);
    transition: 0.4s ease;
  }
}

.toolsItemContainer__delete {
  margin-bottom: 0.3rem;
  padding-right: 1.1rem;
  padding-left: 1rem;
  padding-bottom: 0.1rem;
  border-radius: 4px;
  transition: 0.4s ease;
  cursor: pointer;

  &:hover {
    color: #c02013;
    background-color: #faeef3;
    box-shadow: 2px 1px 5px 1px rgba(0, 0, 0, 0.1);
    transition: 0.4s ease;
  }
}

.icon {
  font-size: 0.9rem;
  margin-bottom: 0.13rem;
}

.text {
  font-size: 0.9rem;
}
