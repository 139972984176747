.bodyNavBar {
  top: 0;
  left: 0;
  right: 0;
  height: 13rem;
}

.upperNavBar {
  width: 70rem;
  height: 10rem;

  margin-right: auto;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.upperNavBar:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 10rem;

  position: absolute;
  display: block;

  content: " ";

  background-image: url("../../Images/rose.png");
  background-size: cover;
  box-shadow: 10px 1px 10px 6px rgba(0, 0, 0, 0.35);
  opacity: 0.8;
}

.titleNavBar {
  padding-top: 0.1rem;
  opacity: 1;

  color: white;
  font-size: 3.4rem;
  font-family: "Allura", cursive;
  z-index: 1;
  background-color: black;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 7px;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.4);
}

.lowerNavBar {
  width: 70rem;
  height: 2.5rem;

  margin-right: auto;
  margin-left: auto;

  display: flex;
  justify-content: space-between;
}

.lowerNavBar:before {
  top: 10rem;
  left: 0;
  width: 100%;
  height: 2.5rem;

  position: absolute;
  display: block;

  content: " ";
  background-color: #e5acb6;
  opacity: 0.2;

  box-shadow: 2px 0 10px 6px rgba(0, 0, 0, 0.5);
}

.floatLeftItems {
  padding-top: 0.65rem;
  display: inline-flex;
}

.floatRightItems {
  padding-top: 0.2rem;
  display: inline-flex;
  column-gap: 7px;
}

.navDisplay {
  position: absolute;
  display: inline-flex;
  column-gap: 2rem;

  font-family: "Roboto", sans-serif;
  font-size: 0.8rem;
}

.navDisplay a {
  text-decoration: none;
  text-shadow: none;
  color: black;
  z-index: 1;
  display: flex;
  justify-content: center;
  transition: color 0.3s ease-out;
}

a:hover,
a.hover,
.active {
  text-decoration: none;
  text-shadow: none;
  color: #2f822f !important;
  transition: color 0.3s ease-out;
}
