.containerSignIn {
  width: 70rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.upperNavBar {
  width: 70rem;
  height: 17rem;

  margin-right: auto;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.upperNavBar:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 17rem;

  position: absolute;
  display: block;

  content: " ";

  background-image: url("../../Images/rose.png");
  background-size: cover;
  box-shadow: 2px 0 10px 6px rgba(0, 0, 0, 0.35);
  opacity: 0.8;
}

.titleNavBar {
  padding-top: 0.1rem;
  opacity: 1;

  color: white;
  font-size: 6rem;
  font-family: "Allura", cursive;
  z-index: 1;
  background-color: black;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 7px;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.4);
}

.signInForm {
  display: flex;
  row-gap: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 16rem;
  width: 20rem;
  margin-top: 2rem;

  background-color: #faeef3;
  box-shadow: 2px 0 10px 6px rgba(0, 0, 0, 0.1);
}

.inputs {
  border: 0;
  border-left: 2px solid #d4ccd7;
  box-shadow: none;
  padding-bottom: 0.5em;
  padding-top: 0.5rem;

  background-color: #faeef3;
  text-align: center;
  transition: border-left 1s ease-out;
}

.inputs:focus,
.inputs.focus {
  text-align: center;
  border-style: none;
  outline: none;
  background-color: #faeef3;
  border-left: 2px solid #280137;
  transition: border-left 1s ease-out;
}

.inputs::placeholder,
.inputs:focus::placeholder {
  opacity: 0.5;
  font-weight: 400;
}

.buttonSignIn {
  width: 10rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;

  border-style: none !important;
  border-radius: 5px;

  font-weight: 600;
  background-color: #deb6b0 !important;
  color: white !important;

  transition: ease-in-out all 0.3s;
}

.buttonSignIn:hover,
.buttonSignIn.hover {
  background-color: #be6e62 !important;
}

.signInErrorMessage {
  margin-top: -1rem;
  font-size: small;
  font-weight: 650;
  color: #c8241b;
}
