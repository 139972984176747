.signOut {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.1rem;

  padding-left: 0.5rem;
  padding-right: 0.7rem;

  z-index: 1;
  transition: 0.3s ease-out;

  font-size: 1.2rem;
}

.signOut:hover,
.signOut.hover,
.signOutActive {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.1rem;

  padding-left: 0.5rem;
  padding-right: 0.7rem;
  border-radius: 3px;

  z-index: 1;
  transition: 0.3s ease-out;

  font-size: 1.2rem;
  color: white;
  background-color: #810a27;
  cursor: pointer;
}

.signOutConfirmation {
  display: block;
  position: absolute;

  padding: 0.5rem 0.5rem 0.5rem 1rem;
  margin-top: 6rem;
  margin-right: 13.7rem;

  background-color: black;
  color: white;
  border-radius: 5px;

  text-align: center;
  white-space: nowrap;
  font-size: 0.9rem;
  z-index: 1;

  box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.4);

  .yes {
    display: inline;
    margin-left: 1rem;
    padding: 0.2rem 1rem;

    border-radius: 3px;
    font-weight: bold;

    background-color: #9a3a52;
    transition: 0.3s ease-out;
    cursor: pointer;
  }

  .yes.hover,
  .yes:hover {
    color: #9a3a52;
    background-color: white;
    transition: 0.3s ease-out;
    cursor: pointer;
  }

  .cancel {
    display: inline;
    margin-left: 0.5rem;
    padding: 0.2rem 1rem;

    border-radius: 3px;
    font-weight: bold;

    background-color: #5663a0;
    transition: 0.3s ease-out;
    cursor: pointer;
  }

  .cancel.hover,
  .cancel:hover {
    color: #5663a0;
    background-color: white;
    transition: 0.3s ease-out;
    cursor: pointer;
  }
}

////////////  Fade animation  ////////////
// Appear
.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 200ms linear;
}
